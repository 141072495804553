import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const Stuff = () => (
  <Layout>
  </Layout>
)

export default Stuff;
